import React from 'react'
import Content from './Content'

import '../styles/main.css'

export default function  Main (){
    return(
        <div className='main'>
            <Content />
        </div>
        
    )
}